<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Agents</h1>
      </div>
      <div class="flex">
        <div>
          <input
            class="p-2 border w-80 rounded"
            v-model="filter"
            @keyup="search()"
            type="search"
            placeholder="Search"
          />
        </div>
      </div>
      <router-link
        to="/agents/add"
        class="border px-6 py-2 rounded-lg text-sm flex justify-center items-center hover:bg-gray-100"
      >
        New Agent
        <i class="bx bxs-plus-circle pl-1"></i>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Balance
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="agents" class="bg-white divide-y divide-gray-200">
                <tr v-for="(agent, i) in agents.data" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <div
                          class="h-10 w-10 rounded-full bg-gray-200 flex justify-center items-center"
                        >
                          {{ agent.entity_name[0] }}
                        </div>
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ agent.entity_name }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ agent.contact_person }} /
                          {{ agent.contact_person_number }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">GMD {{ agent.balance }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      {{ agent.status ? "active" : "in-active" }}
                    </span>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div class="flex justify-end">
                      <button
                        @click="onDropDown(i)"
                        class="relative z-10 block rounded-md bg-white text-xl p-2 focus:outline-none"
                      >
                        <i class="bx bx-dots-vertical-rounded"></i>
                      </button>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <div
                        v-show="dropdownOpen.isOpen && dropdownOpen.index == i"
                        @click="onDropDown(i)"
                        class="absolute right-10 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20"
                      >
                        <router-link
                          :to="{
                            path: `/agents/${agent.id}`,
                          }"
                          class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                          >View
                        </router-link>
                        <router-link
                          :to="{
                            path: `/agents/${agent.id}/edit`,
                          }"
                          class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                          >Edit
                        </router-link>
                        <button
                          @click="handleBlock(agent.id)"
                          class="block px-4 font-bold w-full py-2 text-right text-sm text-red-600 capitalize hover:bg-blue-500 hover:text-white"
                        >
                          {{ agent.status ? "Block" : "Unblock" }}
                        </button>
                        <button
                          @click="showConfirmModal(agent)"
                          class="block px-4 font-bold w-full py-2 text-right text-sm text-red-600 capitalize hover:bg-blue-500 hover:text-white"
                        >
                          Reset Password
                        </button>
                      </div>
                    </transition>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="agents">
              <pagination :data="agents" :midSize="2" @clicked="loadData"> </pagination>
            </div>

            <Confimation
              v-show="isConfirmModalVisible"
              modalHeadline="Please confirm?"
              :isLoading="isLoading"
              :confirmMessage="getMessage()"
              @confirmEvent="confirmRequest"
              @close="closeConfirmModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import Pagination from "../../components/Pagination";
import Confimation from "../../components/Confimation.vue";
import checkPermissionExist from "../../mixins/checkPermissionExist";

export default {
  middleware: "auth",
  components: { Pagination, Confimation },

  layout: "default",

  mixins: [checkPermissionExist],

  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    agents: [],
    isLoading: false,
    filter: "",
    onFilter: false,
    filterAgents: "",
    filterDate: "",
    searchStaffs: [],

    selectedAgent: null,
    isConfirmModalVisible: false,
    dropdownOpen: {
      isOpen: false,
      index: null,
    },
    userHasConfirmed: false,
    modalType: "",
  }),

  created() {
    var _this = this;
    _this.loadData(1);
    if (!this.checkPermissionExist(this.user.data, "agents-read")) {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    search() {
      var vm = this;
      this.searching = true;
      vm.loadData(1, vm.filter);
    },

    rqFilter() {
      this.onFilter = !this.onFilter;

      if (this.onFilter) {
        this.loadBranches();
        this.loadStaffs();
      }
    },

    getMessage() {
      if (this.selectedAgent) {
        return `Are you sure you want to reset ${this.selectedAgent.entity_name}'s password`;
      }

      return "";
    },

    async loadData(pageNo, filter) {
      console.log("This is the page number: ", pageNo);

      if (filter) {
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(`/agents?page=${pageNo}&filter=${filter}`);

        console.log(data);

        this.agents = data;
        this.searching = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },

    async handleBlock(id) {
      console.log("id", id);
      const agent_id = id;
      try {
        const { data } = await axios.patch(`agent/${agent_id}/block`, {
          agent_id,
        });
        if (data) {
          console.log("response data", data);
          this.loadData(1);
        }
      } catch (error) {
        console.error("error in blocking agent", error);
      }
    },

    onDropDown(i) {
      if (this.dropdownOpen.isOpen == false) {
        this.dropdownOpen.isOpen = true;
        this.dropdownOpen.index = i;

        return;
      }

      this.dropdownOpen.isOpen = false;
      this.dropdownOpen.index = null;
    },

    showConfirmModal(agent) {
      console.log("Agent", agent);
      this.selectedAgent = agent;
      // this.modalType = type;
      this.isConfirmModalVisible = true;
    },

    closeConfirmModal() {
      this.selectedAgent = null;
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      this.resetPassword();
    },

    async resetPassword() {
      // Submit the form
      this.isLoading = true;

      try {
        await axios.patch(`/agent/update/password?agent_id=${this.selectedAgent.id}`);

        this.isLoading = false;

        this.loadData(1);
        this.closeConfirmModal();

        Swal.fire({
          icon: "success",
          title: "Password Successfully Rest",
        });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
  },
};
</script>
